import * as globalTypes from "@/base/store-common/types.js";
import types from "./types.js";


const initialState = {
    juiceStoriesRatingById: {},
    juiceStoriesExtraJuiceRatingById: {},
    extraJuicesRatingById: {},
    assignmentExtraJuiceRatingById: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_UI_STORIES_RATING_JUICE_STORY_LOADING: {
            const { storyId, rating } = action.payload;

            const prevByStoryId = state.juiceStoriesRatingById[storyId] || {};

            return {
                ...state,
                juiceStoriesRatingById: {
                    ...state.juiceStoriesRatingById,
                    [storyId]: {
                        ...prevByStoryId,
                        loadingRatingByName: rating,
                        error: "",
                    },
                },
            };
        }

        case types.SET_UI_STORIES_RATING_JUICE_STORY: {
            const { storyId, error } = action.payload;

            const prevByStoryId = state.juiceStoriesRatingById[storyId] || {};

            return {
                ...state,
                juiceStoriesRatingById: {
                    ...state.juiceStoriesRatingById,
                    [storyId]: {
                        ...prevByStoryId,
                        error,
                        loadingRatingByName: "",
                    },
                },
            };
        }

        /* --- */

        case types.SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE_LOADING: {
            const { extraJuiceId, rating } = action.payload;

            const prevByExtraJuiceId = state.juiceStoriesExtraJuiceRatingById[extraJuiceId] || {};

            return {
                ...state,
                juiceStoriesExtraJuiceRatingById: {
                    ...state.juiceStoriesExtraJuiceRatingById,
                    [extraJuiceId]: {
                        ...prevByExtraJuiceId,
                        error: "",
                        loadingRatingByName: rating,
                    },
                },
            };
        }

        case types.SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE: {
            const { extraJuiceId, error } = action.payload;

            const prevByExtraJuiceId = state.juiceStoriesExtraJuiceRatingById[extraJuiceId] || {};

            return {
                ...state,
                juiceStoriesExtraJuiceRatingById: {
                    ...state.juiceStoriesExtraJuiceRatingById,
                    [extraJuiceId]: {
                        ...prevByExtraJuiceId,
                        error,
                        loadingRatingByName: "",
                    },
                },
            };
        }

        /* --- */

        case types.SET_UI_STORIES_RATING_EXTRA_JUICE_LOADING: {
            const { extraJuiceId, rating } = action.payload;

            const prevByExtraJuiceId = state.extraJuicesRatingById[extraJuiceId] || {};

            return {
                ...state,
                extraJuicesRatingById: {
                    ...state.extraJuicesRatingById,
                    [extraJuiceId]: {
                        ...prevByExtraJuiceId,
                        error: "",
                        loadingRatingByName: rating,
                    },
                },
            };
        }

        case types.SET_UI_STORIES_RATING_EXTRA_JUICE: {
            const { extraJuiceId, error } = action.payload;

            const prevByExtraJuiceId = state.extraJuicesRatingById[extraJuiceId] || {};

            return {
                ...state,
                extraJuicesRatingById: {
                    ...state.extraJuicesRatingById,
                    [extraJuiceId]: {
                        ...prevByExtraJuiceId,
                        error,
                        loadingRatingByName: "",
                    },
                },
            };
        }

        /* --- */

        case types.SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE_LOADING: {
            const { extraJuiceId, rating } = action.payload;

            const prevByExtraJuiceId = state.assignmentExtraJuiceRatingById[extraJuiceId] || {};

            return {
                ...state,
                assignmentExtraJuiceRatingById: {
                    ...state.assignmentExtraJuiceRatingById,
                    [extraJuiceId]: {
                        ...prevExtraJuiceId,
                        error: "",
                        loadingRatingByName: rating,
                    },
                },
            };
        }

        case types.SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE: {
            const { extraJuiceId, rating } = action.payload;

            const prevExtraJuiceId = state.assignmentExtraJuiceRatingById[extraJuiceId] || {};

            return {
                ...state,
                assignmentExtraJuiceRatingById: {
                    ...state.assignmentExtraJuiceRatingById,
                    [extraJuiceId]: {
                        ...prevExtraJuiceId,
                        error: "",
                        loadingRatingByName: rating,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
